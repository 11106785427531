import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import toast, { Toaster } from 'react-hot-toast';
import { generateShareableLink } from './shareMindMap';
import './MindmapRatings.css';

const SurveyModal = ({ isOpen, onClose, onTakeSurvey, isNegativeFeedback }) => {
  if (!isOpen) return null;
  return (
    <div className="mindmap-survey-overlay">
      <div className="mindmap-survey-container">
        <div className="mindmap-survey-header">
          <h2>{isNegativeFeedback ? 'Help Us Improve!' : 'Get 1 Month Free!'}</h2>
        </div>
        
        <div className="mindmap-survey-content">
          {isNegativeFeedback ? (
            <p>
              We're sorry the mindmap didn't meet your expectations. Share your thoughts in a quick survey and help us improve. Receive a coupon for 1 month free.
            </p>
          ) : (
            <p>
              Thank you for your positive feedback! Take our quick user survey and receive a coupon for 1 month free.
            </p>
          )}
          
          <div className="mindmap-survey-buttons">
            <button
              onClick={onTakeSurvey}
              className="mindmap-survey-primary-btn"
            >
              {'Take Survey Now'}
            </button>
            
            <button
              onClick={onClose}
              className="mindmap-survey-secondary-btn"
            >
              Maybe Later
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const MindmapRatings = ({ userId, fileKey, identityId }) => {
  const [hasRated, setHasRated] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isNegativeFeedback, setIsNegativeFeedback] = useState(false);

  // DynamoDB client setup
  const docClient = new AWS.DynamoDB.DocumentClient();

  const fetchUserEmail = async () => {
    try {
      const response = await docClient.get({
        TableName: 'pdftomindmapUsers',
        Key: { userID: userId }
      }).promise();
      if (response.Item) {
        setUserEmail(response.Item.Email);
      } else {
        console.error("User not found");
      }
    } catch (error) {
      console.error("Error fetching user email:", error);
    }
  };

  const checkRating = async () => {
    try {
      const data = await docClient.get({
        TableName: 'MindmapRatings',
        Key: { UserID: userId }
      }).promise();
      const fileRated = data.Item?.pdf_ratings?.some(r => r.pdf_location === `private/${userId}/${fileKey}`);
      setHasRated(fileRated);
    } catch (error) {
      console.error("Error accessing DynamoDB", error);
    }
  };

  const handleModalOpen = (isNegative) => {
    setIsNegativeFeedback(isNegative);
    setShowModal(true);
  };

  const handleSurveyClick = () => {
    if (isNegativeFeedback) {
      window.open('https://looplytic.com/shared/RzhUTCKlnb', '_blank');
    } else {
      window.open('https://www.looplytic.com/shared/JZb-HN3CKm', '_blank');
    }
  };

  const submitRating = async (ratingType) => {
    const isNegative = ratingType === 'thumbs_down';
    handleModalOpen(isNegative);

    const pdf_location = `private/${userId}/${fileKey}`;
    const shareableUrl = await generateShareableLink(identityId, fileKey);

    if (!shareableUrl) {
      console.error('Failed to generate shareable URL');
      return;
    }

    try {
      await docClient.update({
        TableName: 'MindmapRatings',
        Key: { UserID: userId },
        UpdateExpression: `SET pdf_ratings = list_append(if_not_exists(pdf_ratings, :empty_list), :rating_info),
                          Email = if_not_exists(Email, :email)`,
        ExpressionAttributeValues: {
          ':rating_info': [{
            pdf_location: pdf_location,
            rating: ratingType,
            shareable_url: shareableUrl
          }],
          ':empty_list': [],
          ':email': userEmail
        },
        ReturnValues: "UPDATED_NEW"
      }).promise();

      setHasRated(true);
    } catch (error) {
      console.error("Failed to update rating", error);
      toast.error('Failed to submit your rating. Please try again.');
    }
  };

  useEffect(() => {
    fetchUserEmail();
    checkRating();
  }, [userId, fileKey]);

  if (hasRated && !showModal) {
    return null;
  }

  return (
    <>
      <div className="mindmap-ratings-container">
        <div className="mindmap-rating-button-group">
          <button
            className="mindmap-rating-btn"
            onClick={() => submitRating('thumbs_up')}
            disabled={hasRated}
          >
            <img src="/thumbsup.png" alt="Thumbs Up" width="34" height="34" />
            <span className="mindmap-rating-tooltip">Found it helpful!</span>
          </button>
          <button
            className="mindmap-rating-btn"
            onClick={() => submitRating('thumbs_down')}
            disabled={hasRated}
          >
            <img src="/thumbsdown.png" alt="Thumbs Down" width="34" height="34" />
            <span className="mindmap-rating-tooltip">Not what I wanted</span>
          </button>
        </div>
      </div>
      <SurveyModal 
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onTakeSurvey={handleSurveyClick}
        isNegativeFeedback={isNegativeFeedback}
      />
      
      <Toaster position="bottom-center" />
    </>
  );
};


export default MindmapRatings;